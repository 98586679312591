:root {
    --clr-darkGreen: #124f44;
    --clr-lightGreen: #3cb371;
    --clr-darker: rgb(56, 67, 71);
}

.resumeContainer {
    max-height: 100vh;
    height: 100vh;
    position: absolute;
    top: 0px;
    width: 100%;
    overflow-y: hidden;
    opacity: 1;
    transition: 2s;
}

.resumeWrapper {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    @media only screen and (min-width: 1200px) {
        display: flex;
    }
}

.threeWrapper {
    width: 100%;
    height: 100%;
    position: relative;
    transition: 2s;
}

.threeHidden {
    opacity: 0;
}

.canvas {
    cursor: grab;
    display: flex;
    justify-content: center;
    align-items: center;
}

canvas {
    display: flex;

    height: 100% !important;
    width: 100% !important;
    margin: auto;
}

.canvasText {
    font-family: "Bree Serif", serif;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    font-style: italic;

    p {
        user-select: none;
    }

    @media only screen and (min-width: 1200px) {
        left: 75%;
    }
}

.slowFadeIn {
    animation: mainFadeIn 2s forwards;
    animation-delay: 1.6s;
    opacity: 0;
}

.threeSidePanel {
    text-align: center;
}

.customNav {
    p,
    a,
    span {
        user-select: none;
    }
}

.modalOverlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1075;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    padding-left: 0;
    background-color: rgba(17, 17, 17, 0.9);
    overflow: hidden;
    cursor: pointer;
    max-height: 100%;

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgb(14, 14, 14);
    }

    ::-webkit-scrollbar {
        width: 2px;
        background-color: rgb(14, 14, 14);
    }

    ::-webkit-scrollbar-thumb {
        background-color: gray !important;
    }

    @media only screen and (min-width: 1200px) {
    }

    svg {
        width: 2em;
        height: 2em;
        fill: #384347 !important;
    }
}

.clipboardBackground {
    background-color: #2a3d74;
    height: 100%;
    width: 100%;
    padding: 0.2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    max-height: 100%;
    overflow-y: hidden;
    border-radius: 3px;

    @media only screen and (min-width: 1200px) {
        width: auto;
        margin: auto;
        height: 90%;
        position: relative;
        padding: 20px 5px;
    }
}

.clipboardWrapper {
   width: 100%;
   height: 100%;

   @media only screen and (min-width: 1200px) {
   display: flex;
}
}

.backgroundClipboard,
.backgroundClipboard2,
.backgroundClipboard3,
.backgroundClipboard4 {
    background-color: rgb(245, 237, 237);
    position: absolute;
    z-index: 1;
    height: 95%;
    width: 85%;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 3px;
    max-width: 95%;
    overflow-x: hidden;


    @media only screen and (min-width: 768px) {
        width: 600px;
        height: 95% !important;

        max-width: 600px;
    }

    @media only screen and (min-width: 992px) {
        width: 800px;
        height: 95% !important;

        max-width: 800px;
    }

    @media only screen and (min-width: 1200px) {
        width: 80%;
        height: 100%;
        top: auto !important;
        bottom: auto !important;
    }
}
.backgroundClipboard {
    top: 8px;

    @media only screen and (min-width: 768px) {
        transform: rotate(-1.2deg) translateX(20px);
        // transform:translateX(20px);
        right: auto;

        top: auto;
        margin-top: -10px;
        bottom: auto;
    }
}
.backgroundClipboard2 {
    transform: rotate(1.5deg);
    top: 16px;
    left: 14px;

    @media only screen and (min-width: 768px) {
        transform: translateX(-16px);

        top: auto;
        bottom: auto;
        left: auto;
    }
}
.backgroundClipboard3 {
    top: 10px;
    transform: rotate(-3deg);
    right: 20px;

    @media only screen and (min-width: 768px) {
        transform: rotate(-1deg) translateX(-20px);
        right: auto;
        margin-top: 15px;
        top: auto;
        bottom: auto;
    }
}
.backgroundClipboard4 {
    transform: rotate(-0.5deg);
    top: 5px;

    @media only screen and (min-width: 768px) {
        transform: rotate(1.2deg) translateX(20px);
        right: auto;

        top: auto;
        bottom: auto;
    }
}

.clipboard {
    background-color: rgb(245, 237, 237);
    height: 98%;
    width: 95%;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 3px;
    z-index: 3;
    transform: rotate(0deg);
    max-width: 95%;
    overflow-x: hidden;
    position: absolute;
    top: 5px;

    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
    }

    li {
        &:before {
            content: "•";
            padding-right: 0.2px;
            align-self: baseline;
        }
    }

    @media only screen and (min-width: 768px) {
        width: 600px;
        height: 95%;
        top: auto;
        bottom: auto;
    }

    @media only screen and (min-width: 992px) {
        width: 800px;
        height: 95% !important;
        max-width: 800px;
    }

    @media only screen and (min-width: 1200px) {
        width: 80%;
        height: 100% !important;
        position: relative;
    }
}

.clipboardContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    margin-top: 10px;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;

    a {
        text-decoration: none;
        color: var(--clr-darker) !important;
        font-weight: bold !important;
    }

    span,
    p,
    li {
        color: rgb(56, 67, 71) !important;
    }

    h1,
    h2,
    h3,
    h4 {
        color: var(--clr-darkGreen) !important;
        text-align: start;
        line-height: normal;
        margin-bottom: 0.2em;
    }

    p,
    span,
    a {
        font-family: "Inter" !important;
        line-height: normal;
    }

    h1 {
        color: var(--clr-darkGreen) !important;
        font-size: 1.8em;

        @media only screen and (min-width: 992px) {
            font-size: 2.6em;
        }
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 10px;
        padding: 20px;

        .clipboardContent {
            padding: 15px;
        }
    }
}

.clipboardBackgroundTop {
    width: 50%;
    background-color: #0c0c0c;
    top: 0;
    position: absolute;
    height: 40px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);

    @media only screen and (min-width: 768px) {
        height: 80px;
    }

}

.cross {
    font-size: 1.5em;
    position: absolute;
    top: 0.5%;
    right: 0.5%;
    z-index: 99999;
    cursor: pointer;

    svg {
        font-size: 1em;
    }

    @media only screen and (min-width: 768px) {
        svg {
            fill: white !important;
        }
    }
}

.resumeContact {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-weight: bold;

    .resumeContactItem {
        display: flex;
        font-size: 0.5em !important;
        align-items: center;
        justify-content: flex-start;
        width: fit-content;

        svg {
            height: 0.5em;
            width: 0.5em;
            margin: 2px;
            fill: var(--clr-lightGreen) !important;
        }

        span {
            font-size: 12px !important;
            font-weight: bold;
        }
    }

    @media only screen and (min-width: 992px) {
        .resumeContactItem {
            display: flex;
            font-size: 1em !important;

            svg {
                height: 0.8em;
                width: 0.8em;
                margin: 2px;
                fill: var(--clr-lightGreen) !important;
            }

            span {
                font-size: 1em !important;
                font-weight: bold;
            }
        }
    }
}

.resumeName {
    text-align: start;
}

.clipboardTop {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.resumeTopLeft {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.resumeTopRight {
    width: 80px;
    height: 80px;

    img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        aspect-ratio: 1;
    }

    @media only screen and (min-width: 768px) {
        width: 120px;
        height: 120px;
        margin-right: 10%;
    }
}

.resumeMain {
    display: grid;
    grid-template-columns: 1fr 45%;
    column-gap: 10px;
    grid-auto-flow: column;
    margin-top: 20px;
    width: 100%;
    background-color: rgb(245, 237, 237);

    .selfpoint {
        color: #f7a700 !important;
    }

    h2 {
        font-size: 1em;
        width: 100%;
        border-bottom: 2px solid var(--clr-lightGreen);
        margin-bottom: 4px;

        @media only screen and (min-width: 992px) {
            font-size: 1.4em;
        }
    }

    h3 {
        font-size: 0.8em;
        margin-bottom: 0.1em;

        @media only screen and (min-width: 992px) {
            font-size: 1.2em;
        }
    }

    .resumeMainSection {
        .resumeSkills {
            display: flex;
            flex-wrap: wrap;
        }
        .resumeSkill {
            font-size: 8px;
            background-color: var(--clr-darkGreen);
            color: white;
            border-radius: 4px;
            width: fit-content;
            padding: 1px 4px;
            margin: 2px;
        }

        a,
        p,
        span,
        li,
        h4 a {
            font-size: 10px !important;
        }

        h4,
        h4 a {
            font-size: 10px !important;

            @media only screen and (min-width: 992px) {
                font-size: 1em !important;
            }
        }

        h4 a {
            font-weight: bold !important;
            color: var(--clr-lightGreen) !important;
        }

        @media only screen and (min-width: 768px) {
            a,
            p,
            span,
            li {
                font-size: 12px !important;
            }

            .resumeSkill {
                font-size: 10px;
            }
        }

        @media only screen and (min-width: 992px) {
            a,
            p,
            span,
            li {
                font-size: 14px !important;
            }

            .resumeSkill {
                font-size: 14px;
                padding: 2px 6px;
            }
        }
    }

    .resumeExperienceSection {
        border-bottom: 1px dotted gray;
        svg {
            width: 12px;
            height: 12px;
        }

        .resumeExperienceInfo {
            margin-bottom: 0.2em;
        }
    }
}

.resumeStrengthWrapper {
    display: flex;
    flex-direction: row;
    border-bottom: 1px dotted gray;
    padding: 5px 0px;

    .resumeStrengthIcon {
        display: flex;
        align-items: flex-start;
        margin-right: 0.2em;
    }

    svg {
        width: 14px;
        height: 14px;
        fill: var(--clr-lightGreen) !important;
    }
}

.education {
    margin-top: 5px;
    svg {
        width: 14px;
        height: 14px;
        fill: var(--clr-lightGreen) !important;
    }
}
