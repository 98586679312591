$input-background: rgba(22, 33, 44, 0.644);
$input-text-inactive: #7881a1;
$input-text-active: #bfd2ff;

@keyframes gradientBackground {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 100% 0;
    }
}

form {
    width: 100%;
    z-index: 10;
}

.formContainer {
    display: flex;
    text-align: center;
    width: 100%;
}

.input {
    width: 95%;
    margin: 0.5em auto;
    position: relative;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    padding: 1.3em 4%;
    background: $input-background;
    &:after {
        content: "";
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 999;
        height: 2px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        background-position: 0% 0%;
        background: linear-gradient(
            to left,
            #c6b0ff,
            #17e0e7,
            #fafc99,
            #42b3b3,
            #bba6f0,
            #00e0e7
        );
        background-size: 500% auto;
        animation: gradientBackground 3s linear infinite;
    }
}

.contactContainer a {
    text-decoration: none;
}
.contactContainer input,
textarea {
    border-style: none;
    background: transparent;
    outline: none;
}

.input input,
textarea {
    flex-grow: 1;
    color: $input-text-active;
    font-size: 1.5rem;
    line-height: 2.4rem;
    vertical-align: middle;
    &::-webkit-input-placeholder {
        color: $input-text-inactive;
    }
}

.contact .creativeHeader {
    margin-bottom: 0px;
    margin-top: 0px;
}

.contactContainer {
    width: 100%;
    margin: 100px auto 0px auto;
}

.contactContainer h2 {
    text-align: center;
}

textarea {
    resize: none;
}

.submitForm {
    background: rgba(22, 33, 44, 0.644);
    font-size: 1em;
    color: white;
    text-transform: uppercase;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    text-decoration: none;
    transition: 1s;
    margin: 0px auto 0px auto;
    border: none;
}

.submitForm:hover {
    background: rgb(24, 24, 24);
    color: white;
    cursor: pointer;
}

.contactIllustration {
    background-image: url(../assets/undraw_contact_us_15o2.svg);
    width: 730px;
    background-size: cover;
}

.contactRow {
    display: flex;
    justify-content: space-evenly;
    margin-top: 100px;
    flex-direction: column;
    width: 100%;
}

.contactIllustration {
    width: 300px;
    margin: 50px auto 0px auto;
}

@media screen and (min-width: 600px) {
    .input {
        width: 70%;
        margin: 1em auto;
    }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
    .contactIllustration {
        width: 600px;
        margin: 50px auto 0px auto;
    }
}

@media only screen and (min-width: 1200px) {
    .input {
        width: 700px;
        margin: 0 auto;
        margin: 20px;
    }

    .contactIllustration {
        width: 50%;
        margin: 50px 0px 0px auto;
    }

    .formContainer {
        width: auto;
    }

    .contactRow {
        flex-direction: row;
    }
}
