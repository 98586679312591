* {
    box-sizing: border-box;
}

.projects {
    width: 100%;
    margin-bottom: 100px;
}

.projectsContainer {
    align-items: center;
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: column;
}

.projects h2 {
    text-align: center;
    padding-top: 50px;
}

.productCard {
    display: flex;
}

.card {
    min-height: 50vh;
    width: 95%;
    padding: 0%;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
    justify-content: space-evenly;
    transition: 2s;
    margin: 50px 0px;
    z-index: 10;
    opacity: 0.8;
}

.card li {
    display: inline-block;
    padding: 0 0.8em;
    background-color: rgb(17, 19, 27);
    color: white;
    border-width: 1px 1px 2px 1px;
    border-style: solid;
    border-color: #222;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.75em;
    margin: 2px;
    position: relative;
    text-shadow: -1px -1px 0 #3f3f3f, -1px 0 0 #3f3f3f, -1px 1px 0 #3f3f3f,
        0 -1px 0 #3f3f3f, 0 0 0 #3f3f3f, 0 1px 0 #3f3f3f, 1px -1px 0 #3f3f3f,
        1px 0 0 #3f3f3f, 1px 1px 0 #3f3f3f;
    -moz-transition: -moz-transform 0.285s cubic-bezier(0.5, 1.5, 0.5, 1.5);
    -o-transition: -o-transform 0.285s cubic-bezier(0.5, 1.5, 0.5, 1.5);
    -webkit-transition: -webkit-transform 0.285s
        cubic-bezier(0.5, 1.5, 0.5, 1.5);
    transition: transform 0.285s cubic-bezier(0.5, 1.5, 0.5, 1.5);
    z-index: 4;
}

.card:hover:before {
    opacity: 1;
}
.card:hover .info {
    opacity: 1;
}

.card:hover img {
    animation-play-state: paused;
    filter: brightness(50%)
}
.card:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
}

.card img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    animation: imagePosition 20s infinite;
}

@keyframes imagePosition {
    0% {
        object-position: center top;
    }
    50% {
        object-position: center bottom;
    }
    100% {
        object-position: center top;
    }
}
.card .info {
    position: relative;
    z-index: 3;
    color: white;
    opacity: 0;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;

}

.disabled {
        cursor:not-allowed !important; 
        background-color: #C6BEB3 !important;
        color: white;
}
.card .info h1 {
    margin: 0px;
    font-size: 1.4em;
}
.card .info p {
    letter-spacing: 1px;
    font-size: 15px;
    margin-top: 8px;
    margin-bottom: 20px;
}
.projectLink {
    font-size: 1em;
    padding: 0.5rem;
    outline: none;
    border: none;
    border-radius: 3px;
    background: #11131b;
    font-weight: bold;
    cursor: pointer;
    transition: 0.4s ease;
    z-index: 3;
    text-decoration: none !important;
    margin-top: auto;
    width: fit-content;
    margin: 0px auto;

    &:hover {
        background: white;
        color: #11131b !important;
    }

    a {
    text-decoration: none !important;
    color: inherit !important;

    &:hover {
        background: inherit;
        color: inherit !important
    }

    }

    
}


.marginLeft {
    position: relative;
    margin-top: 50px;
    left: 0;
}

.projectTitle {
    max-width: 80%;
    margin: 0px auto 0px auto;
}

.projectLink {
    margin-top: 15px;
}

.projectTitle h4 {
    font-size: 1em;
}

@media screen and (min-width: 600px) {
    .projects {
        margin-bottom: 100px;
    }
    .card .info h1 {
        font-size: 1.7em;
        margin: 5px;
    }
    .card .info h4 {
        font-size: 20px;
        margin: 10px;
    }
    .projectLink {
        font-size: 1.2em;
        padding: 0.6rem;
    }

    .projectLink {
        margin-top: 30px;
    }
    .card .projectTools li {
        font-size: 1em;
        padding: 0 0.8em;
    }
}

@media only screen and (min-width: 992px) {
    .card .info h1 {
        font-size: 2em;
        margin: 5px;
    }
    .card .info h4 {
        font-size: 1.5em;
        margin: 10px;
    }
    .card .projectTools li {
        font-size: 1.2em;
        padding: 0 0.8em;
    }
    .projectLink {
        font-size: 1.2em;
        padding: 0.7em;
    }

    .card {
        width: 1000px;
        height: 700px;
        padding: 1.5rem;
    }
    .projectTitle {
        max-width: 60%;
    }
    .card:hover {
        transform: translateY(20px);
    }
    .card:hover .info {
        transform: translateY(50px);
    }
}

@media only screen and (min-width: 1200px) {
    .projects {
        margin-bottom: 200px;
    }
    .tooltip {
        display: none;
    }
    .marginLeft {
        left: 5%;
    }
    .marginRight {
        right: 5%;
    }
    .projectTools {
        width: 75%;
        margin: 0px auto;
    }

    .projectsContainer {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        margin-top: 5rem;
    }

    .card {
        max-width: 900px;
        width: 90%;
        align-self: center;
        justify-self: center;
        position: block !important;
        min-height: 0vh;
        height: 450px;
    }

    .card .info h4 {
        font-size: 1.3em;
        margin: 10px;
    }

    .card:hover .info {
        transform: none;
    }

    .projectLink {
        font-size: 0.8em;
    }

    .card .info li {
        font-size: 0.7em !important;
    }

    .marginRight,
    .marginLeft {
        margin-right: 0px;
        margin-left: 0px;
        right: 0;
        left: 0;
    }

    .marginRight {
        transform: translateY(-100px);
    }

    .marginRight:hover {
        transform: translateY(-100px);
    }

    .marginLeft:hover {
        transform: translateY(100px);
    }
    .marginLeft {
        transform: translateY(100px);
    }
}

@media only screen and (min-width: 1900px) {
    .card {
        height: 500px;
        min-height: 500px;
    }

    .card .info li {
        font-size: 1.2em !important;
    }

    .projectLink {
        font-size: 1em;
    }
}
