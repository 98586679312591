.loadingContainer {
    position: relative;
    height: 100vh;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: rgb(11, 12, 16);
    overflow: hidden;
    max-height: 100vh;
}
.loadingWrapper {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgb(11, 12, 16);
    z-index: 999999999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-height: 100vh;
}
