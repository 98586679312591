* {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    color: white;
    -webkit-font-smoothing: antialiased;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
}

#spinner {
    position: absolute;
    top: 50vh;
    left: 50vw;
}

.themeBlue {
    color: #00dacb;
}

.visible {
    opacity: 1;
}

.hidden {
    opacity: 0;
}

body,
html,
#root {
    max-width: 100%;
    overflow-x: hidden;
}

h1,
h2 {
    font-family: "Bree Serif", serif;
    font-weight: 800;
    margin-bottom: 10px;
}

h1 {
    font-size: 7em;
}

.timeline h2 {
    text-align: center;
    margin-top: 50px;
}

.vertical-timeline-element-content {
    padding: 0.5em !important;
}

h2 {
    font-size: 2.2em;
    color: rgb(218, 255, 249);
    line-height: 74px;
    font-weight: 700;
    text-align: left;
}

h3 {
    font-family: "Bree Serif", serif;
    color: #c5c6c7;
    font-size: 1.6em;
}

p,
li,
span {
    font-size: 16px !important;
    color: #f3f3f3;
    margin-bottom: 0px;
}

a:hover {
    cursor: pointer;
}

.fadeIn {
    animation: fadeIn !important;
    animation-duration: 2s !important;
}
.fadeInDelay {
    animation: fadeIn;
    animation-delay: 1s;
    animation-duration: 4s;
}

.fadeOut {
    transform: scale(1.5);
}

body {
    background: #0b0c10;
    scroll-behavior: smooth;
    max-width: 100vw;
    overflow-x: hidden;
}

.bodyContainer {
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    z-index: -5;
}

.mainContainer {
    max-width: 1040px;
    margin: 0px auto 0px auto;
}

.timeline {
    width: 100%;
    justify-content: center;
    text-align: center;
    opacity: 0.8;
}

.footer {
    margin-top: 50px;
    background: rgb(22, 33, 44);
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0px;
    z-index: 10;

    p {
        z-index: 10;
    }
}

.timeline {
    ul {
        margin-top: 1em;
    }
}

canvas {
    cursor: grabbing;
}

.timeline span {
    text-align: start;
    font-size: 1.2em !important;
    color: white !important;
}

.vertical-timeline-element-content {
    -webkit-box-shadow: 0 3px 0 #66fcf1 !important;
    box-shadow: 0 3px 0 #66fcf1 !important;

    li,
    p {
        text-align: start;
    }
}

@media screen and (max-width: 576px) {
    h1 {
        font-size: 2.3em;
    }
    h1,
    h2,
    h3 {
        text-align: center;
    }

    .vertical-timeline {
        width: 100% !important;
        max-width: 100% !important;
    }
    .vertical-timeline h3 {
        font-size: 1.8em;
    }
}

@media screen and (min-width: 600px) {
    .vertical-timeline-element-content {
        padding: 1em !important;
    }
}

@media only screen and (min-width: 768px) {
    h2 {
        font-size: 2.8em;
    }
    h3 {
        font-size: 2.3em;
    }
    .timeline h4 {
        font-size: 1.4em;
    }
    .vertical-timeline-element-content {
        padding: 1em;
    }
    p,
    span,
    li {
        font-size: 18px !important;
    }
}

@media only screen and (min-width: 992px) {
    h2 {
        font-size: 2.8em;
    }
    h3 {
        font-size: 2.3em;
    }
}

@media only screen and (min-width: 1200px) {
}

.sidebar-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    z-index: 99999999999 !important;
}

.sidebar {
    width: 0;
    background-color: #0f0f0f;
    overflow: hidden;
    transition: width 0.3s, z-index 0s 0.3s;
    padding: 20px;
    flex-direction: column;
    display: none;
    overflow-y: scroll;
    text-align: center;

    h3 {
        margin-top: 20px;
    }
}

.sidebar.expanded {
    width: 100%;
    display: flex;
    max-width: 600px;

    @media screen and (min-width: 1200px) {
        width: 500px;
    }
}

.toggle-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: transform 0.3s ease-out;
    width: 50px !important;
    height: 50px !important;
    background-color: #0f0f0f !important;
    border-radius: 1px !important;
}

.settings-icon {
    transition: transform 0.3s;
    transform: rotate(180deg) !important;
}

.settings-icon.expanded {
    transform: rotate(0deg) !important;
}

.sideBarSelectWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
    margin: 20px 0px;
    align-items: center;
}

.sideBarQualityCard {
    padding: 5px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;

    
    h4 {
        color: rgb(218, 255, 249);
    }

    img {
        border-radius: 10px;
        margin: 10px 0px;

        width: 100%;
    }

    p {
        margin-bottom: 1rem;
    }

    button {
        margin: auto auto 0px auto;
        padding: 7px 10px;
        color: #0f0f0f;
        border-radius: 10px;
        width: 100%;
        border: none;
    }

    button.selectedScene {
        background: rgb(4, 92, 4);
        color: white;
        cursor: not-allowed;
    }

    @media screen and (min-width: 1200px) {
    }
}

.downloadPDF {
    width: 100%;
    padding: 10px 20px;
    color:#0f0f0f;
    border-radius: 5px;
    margin-bottom: 10%;
}

.non-selectable {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .helpBox {
    display: none;

    @media screen and (min-width: 1200px) {
        background-color: rgba(117, 117, 117, 0.3);
        font-weight: bold !important;
        z-index: 9999999;
        position: absolute;
        top: 8%;
        right: 2%;
        padding: 20px;
        display: block;
       
    
        * {
            pointer-events: none;
            user-select: none; /* Standard */
        }
    }
  
  }

  button:disabled {
    cursor:not-allowed;
  }

  .vertical-timeline-element-subtitle {
    margin-top: 5px !important;
  }