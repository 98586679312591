:root {
    --clr-neon: #00dacb;
    --clr-bg: hsl(323 21% 16%);
}

.illustration {
    width: 50vw;
    background-size: cover;
    z-index: 1;
    opacity: 0.4;
    transition: 1s;
    margin: 0px auto 20px auto;
}

.topWrapper {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
}

.topContainer {
    display: flex;
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
}

.topText {
    text-align: center;
    width: 90%;
    height: 100%;
    flex-direction: column;
    display: flex;
    margin: 0px auto;
}

.title {
    width: 100%;
    position: relative;
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.block {
    width: 0%;
    height: 100%;
    background: #08f7fe;
    position: absolute;
    animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
    display: flex;
}

.topWrapper img {
    animation: mainFadeIn 2s forwards;
    animation-delay: 1.6s;
    opacity: 0;
}

.dBig {
    font-size: inherit !important;
}

.dWrapper {
    display: flex;
    font-size: inherit !important;
    height: fit-content !important;
    position: relative;
    margin-left: 10px;
    span {
        color: var(--clr-neon) !important;
        margin: 0px !important;
    }
}

.evelopment {
    border-top: 1px solid var(--clr-neon);
}

.dColumn {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: start;
    transform: translateY(3px);
}

.esign,
.evelopment {
    font-size: 10px !important;
    letter-spacing: 0.5px;
}

h1 {
    font-size: 2.3em;
    font-weight: 800;
    margin-bottom: 10px;
}

h1,
h2,
h3,
h4 {
    text-align: center;
}

.topContainer span.dot {
    width: 0px;
    height: 0px;
    border-radius: 50%;
    background: #08f7fe;
    animation: popIn2 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
    animation-delay: 2s;
    margin-left: 5px;
    margin-top: -10px;
    position: absolute;
    bottom: 13px;
    right: -12px;
}

.topContainer .box .role {
    animation: secFadeIn 2s forwards;
    animation-delay: 3.2s;
    opacity: 0;
    color: #f3f3f3 !important;
    font-weight: 700;
    font-size: 1.5em;
    font-family: "Bree Serif", serif;
    display: block;
    width: 90%;
    margin: 0.5em 0;
    position: relative;
    flex-direction: column;
    text-align: start;
}

.topContainer .box .role .block {
    width: 0%;
    height: 100%;
    background: var(--clr-neon);
    position: absolute;
    display: inline-flex;
}

.inherit {
    font-size: inherit !important;
}

.Typewriter {
    display: inline-block;
}

.Typewriter span {
    font-size: inherit !important;
    color: #dafff9;
}

.neon-button {
    animation: secFadeIn 2s forwards;
    width: fit-content;
    animation-delay: 4s;
    opacity: 0;
    font-family: "Bree Serif", serif;
    font-weight: bold;
    font-size: 1em;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    color: var(--clr-neon);
    border: var(--clr-neon) 0.125em solid;
    padding: 0.3em 1em;
    border-radius: 0.25em;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon), 0 0 0.5em 0 var(--clr-neon);
    position: relative;
    margin: 20px auto 0px auto;
}

.neon-button::after {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 2em 1em var(--clr-neon);
    opacity: 0;
    transition: opacity 100ms linear;
}

.neon-button:hover,
.neon-button:focus {
    color: white;
    text-shadow: none;
}

.neon-button:hover::after,
.neon-button:focus::after {
    opacity: 1;
}

@keyframes mainBlock {
    0% {
        width: 0%;
        left: 0;
    }
    50% {
        width: 100%;
        left: 0;
    }
    100% {
        width: 0;
        left: 100%;
    }
}

@keyframes secBlock {
    0% {
        width: 0%;
        left: 0;
    }
    50% {
        width: 100%;
        left: 0;
    }
    100% {
        width: 0;
        left: 100%;
    }
}

@keyframes mainFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes popIn {
    0% {
        width: 0px;
        height: 0px;
        background: #08f7fe;
        border: 0px solid #ddd;
        opacity: 0;
    }
    50% {
        width: 10px;
        height: 10px;
        background: #09fbd3;
        opacity: 1;
        bottom: 45px;
    }
    65% {
        width: 7px;
        height: 7px;
        bottom: 0px;
        width: 40px;
    }
    80% {
        width: 10px;
        height: 10px;
        bottom: 27px;
    }
    100% {
        width: 7px;
        height: 7px;
        background: var(--clr-neon);
        border: 0px solid #222;
        bottom: 30px;
    }
}

@keyframes popIn2 {
    0% {
        opacity: 0;
    }
    50% {
    }
    65% {
    }
    80% {
        width: 10px;
        height: 10px;
        bottom: 27px;
    }
    100% {
        width: 7px;
        height: 7px;
        background: var(--clr-neon);
        border: 0px solid #222;
        bottom: 10px;
        opacity: 1;
    }
}

@keyframes secFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@media screen and (min-width: 600px) {
    .topContainer h1 {
        font-size: 3.8em;
    }
}

@media only screen and (min-width: 768px) {
    .topContainer .box .role {
        width: 85%;
    }
    .neon-button {
        margin-top: 20px;
        font-size: 1.2em;
    }

    .topContainer h1 {
        font-size: 5em;
    }
    .topWrapper {
        margin-bottom: 2em;
    }
}

@media only screen and (min-width: 992px) {
    .topText {
        transform: translateY(0px);
    }
    .topContainer .box .role {
        font-size: 2em;
    }
    .topContainer span.dot {
        animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
        animation-delay: 2s;
    }
    .topContainer h1 {
        font-size: 6em;
    }
    .topContainer .box .role {
        font-size: 2.5em;
    }
    .neon-button {
        font-size: 1.5em;
    }
}

@media only screen and (min-width: 1200px) {
    .topText {
        position: absolute;
        left: 0;
        width: 70%;
        top: 35vh;
    }
    .illustration {
        position: absolute;
        width: 30vw;
        right: 5%;
        top: 25vh;
    }
    .topWrapper {
        flex-direction: row;
        height: 100%;
        position: relative;
    }
    .title {
        width: fit-content;
    }
    .topContainer .box .role {
        margin: 0px;
        font-size: 2em;
    }
    .box {
        padding-left: 10%;
    }
    .neon-button {
        margin: 30px 25% 0px 0;
    }
}
@media only screen and (min-width: 1600px) {
    .neon-button {
        margin: 20px 0px 0px 30%;
    }

    .illustration {
        width: 35vw;
        top: 20vh;
        right: 10%;
    }
    .topContainer .box .role {
        width: fit-content;
    }

    .neon-button {
        margin: 30px 40% 0px 0;
    }
}
