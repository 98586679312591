.threeDProjectsWrapper {
    margin-top: 150px;

    a {
        text-decoration: none !important;

    }
}

.threeDProjectContainer {
    display: flex;
    flex-direction:column;
    max-width: 100%;
    padding: clamp(1.25em, 4vw, 2.5em);
    margin-bottom: 2em;

    h2 {
        text-align: start;
        border-bottom: 1px solid gray;
    }

    img {
        width: 100%;
        object-fit: cover;
        border-radius: 5px;

       
    }

    .threeDProjectTop {
        display: flex;
        justify-content: space-between;
    }

    .threeDProjectTop {
        margin-bottom: 0.5em;
    }

    @media screen and (min-width: 1200px) {
            max-width: 1000px;
            margin: 2em auto;
    }
}

.projectBackButton {
    position: absolute;
    top: 10%;
    left: 2%;
}

.gameContainer {
    margin-top: 150px;
}

.gameImageGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(0, 1fr);
    grid-gap: 10px;
    height: 100vh; 
    width: 95%;
    margin: auto;
  }
  
 /* Mobile layout (default) */
.gameImageGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns */
    grid-auto-rows: minmax(0, 1fr); /* Allow rows to adjust to content height */
    grid-gap: 5px;
    height: 100vh; /* Ensure the grid fits within viewport height on mobile */
  }
  
  .gameImageGrid img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the images cover the entire grid cells */
    border-radius: 1px;
  }
  
  /* Adjust specific grid items */
  .grid-item {
    border-radius: 1px;
    overflow: hidden;
  }
  
  .item1 {
    grid-row: span 2;
    grid-column: span 2;
  }
  
  .item2 {
    grid-row: span 1;
    grid-column: span 1;
  }
  
  .item3 {
    grid-row: span 1;
    grid-column: span 1;
  }
  
  .item4 {
    grid-row: span 3;
    grid-column: span 1;
  }
  
  .item5 {
    grid-row: span 3;
    grid-column: span 2;
  }
  
  .item6 {
    grid-row: span 1;
    grid-column: span 2;
  }
  
  .item7 {
    grid-row: span 3;
    grid-column: span 1;
  }
  
  .item8 {
    grid-row: span 2;
    grid-column: span 2;
  }
  
  /* Remove any margins or padding from the container to avoid gaps */
  