body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-padding-top: 250px;
}

p,
h3,
li,
a,
h4,
textarea,
input {
  font-family: "Inter", sans-serif;
}



::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(14, 14, 14);
}

::-webkit-scrollbar {
  width: 5px;
  background-color: rgb(14, 14, 14);
}

::-webkit-scrollbar-thumb {
    background-color: #00dacb !important;
}


@media screen and (min-width: 768px) {
    ::-webkit-scrollbar {
  width: 10px;
}

}

