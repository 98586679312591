.customNav {
  width: 100%;
  background: rgba(255, 255, 0, 0);
  display: flex;
  justify-content: space-evenly;
  margin: 0px;
  z-index: 99999;
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.10);
  align-items: center;
  border: none;
  padding: 10px 0px;

  svg {
    fill: #a8a8a8;
  }

  img {
    width: 25px;
    height: 25px;
  }
}

/*   .mobileOnly {
    display: none;
  } */

.customNav a {
  z-index: 999;
}

.navWrapper {
    display: flex;
}

.navLink {
  text-decoration: none;
  color: #f0fffd;
  margin: 0px 5px;
}

a.active {
  color: #DAFFF9 !important;
}

.customNav a:hover {
  cursor: pointer;
}

.switchWrapper {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  background-color: #1C1C1E;
  padding: 8px;
  border-radius: 7px;
}

.language {
  cursor: pointer;
  transition: 0.5s;
  font-weight: 500;
}

.activeLang {
color: #DAFFF9;
}


@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: flex-end !important;
  }

}

@media (min-width: 1200px) {

/* .mobileOnly {
  display: block;
} */
}
