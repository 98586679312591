:root {
    --clr-neon: #00dacb;
    --clr-bg: hsl(323 21% 16%);
}

.topBackground {
    width: 100%;
    padding: 0px 0px 50px 0px;
}

.linethrough {
    text-decoration: line-through;
}

.aboutSection {
    max-width: 90%;
    margin: 0px auto 0px auto;
}
.aboutText {
    width: 100%;
}

.greenSpan,
.themeGreen,
.themeGreen a {
    color: var(--clr-neon) !important;
    text-transform: capitalize;
    font-weight: bold;
}

.aboutText h3 {
    margin-bottom: 15px;
    text-align: start;
}

.rightContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.aboutRow {
    flex-direction: column;
    display: flex;
    margin-top: 50px;
}

.aboutRow:first-of-type {
    margin-top: 0px;
}

.aboutInfo {
    grid-template-columns: 1fr;
    display: grid;
}

.aboutColumn {
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
}

.aboutColumn h3 {
    color: #dafff9;
}

.aboutColumn p {
    margin: 2px;
}

.aboutColumn h3 {
    text-align: start;
}

.photo {
    position: initial;
    width: 300px;
    opacity: 1;
    margin: 20px auto 20px auto;
    border-radius: 2%;
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 30%;
    align-self: flex-end;
    background-image: url(../assets/me.png);
    z-index: 0;
}

.bold {
    font-weight: bold;
}

@media screen and (min-width: 600px) {
    .aboutColumn h3 {
        text-align: start;
    }
    .aboutColumn {
        margin: 30px 0px 0px 0px;
        width: 100%;
    }
}

@media only screen and (min-width: 768px) {
    .aboutSection {
        max-width: 80%;
    }
    .aboutInfo {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        width: 100%;
        text-align: center;

        h3 {
            text-align: center;
        }
    }
}

@media only screen and (min-width: 992px) {
    h2 {
        font-size: 3.2em;
    }
    .aboutText h3 {
        font-size: 2.5em;
    }

    .photo {
        width: 400px;
        height: 400px;
    }
}

@media only screen and (min-width: 1200px) {
    h2 {
        font-size: 3.5em;
    }
    .aboutSection {
        display: flex;
        max-width: 85%;
        margin: 0px auto 0px auto;
        flex-direction: column;
        transition: 1s;
    }

    .aboutRow {
        flex-direction: row;
    }

    .aboutText {
        width: 55%;
    }

    .aboutText h3 {
        margin-bottom: 15px;
    }
    .photo {
        width: 300px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 30%;
        height: 300px;
        margin: auto;
        border-radius: 2%;
    }
    .aboutInfo {
        margin-top: 40px;
        margin: 0px auto 0px auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        max-width: 600px;
    }

    .aboutColumn {
        display: flex;
        flex-direction: column;
        margin: 0px 0px 0px 0px;
    }
    .aboutColumn p {
        margin: 2px;
    }

    .aboutColumn h3 {
        color: #dafff9;
    }

    .aboutInfo span {
        color: var(--clr-neon);
    }

    .aboutInfo a {
        color: var(--clr-neon) !important;
    }

    .skillText p {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .rightSkill {
        margin-top: 100px;
        display: flex;
        flex-direction: column;
    }

    #aboutSection {
        position: relative;
    }

    .aboutRow {
        display: flex;
        margin-top: 50px;
        gap: 50px;
    }

    .rightContainer {
        display: flex;
        width: 40%;
        margin-left: 3%;
    }

    .aboutMain {
        display: flex;
    }
}

.skillsSection {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    margin: 0px auto 0px auto;

    h3 {
        text-align: center;
    }

    p {
        font-size: 14px !important;
    }
}
.development {
    width: 90%;
}

.tools {
    width: 90%;
}
.toolsContainer {
    flex-direction: column;
    align-items: center;
}

.toolsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0px auto 0px auto;
    margin-top: 0.5rem;
}
.skillHeader {
    color: white;
}

.toolsContainer h3 {
    color: white;
}
.designContainer {
    width: 95%;
    display: flex;
    justify-content: space-evenly;
    margin: 0px auto 0px auto;
}

.skillsBackground {
    background-image: url(../assets/skillsBackground.jpg);
    width: 100%;
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
}

.imageOverlay {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.61),
        rgba(0, 0, 0, 0.61),
        rgba(0, 0, 0, 0.61),
        rgba(0, 0, 0, 0.61),
        rgba(0, 0, 0, 0.61)
    );
    width: 100%;
    height: 100%;
}

.toolsContainer {
    display: flex;
    width: 100%;
}

.creativeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
}

.skillImageContainer {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 100%;
    max-width: 50px;
}

.skillHeader {
    font-weight: bold;
}

.toolsContainer h3 {
    margin: 40px;
    font-size: 1.9em;
}

.designGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr !important;
    }

@media screen and (min-width: 600px) {
    .toolsContainer h3 {
        margin: 40px;
        font-size: 2.5em;
    }

  
    .toolsContainer {
        width: 80%;
        margin: 0px auto 0px auto;
    }
}

@media only screen and (min-width: 768px) {
    .skillsSection {
        flex-direction: row;
        gap: 20px;
    }
    .toolsGrid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin: initial;
    }

 }

@media only screen and (min-width: 992px) {
    .skillsSection h2,
    h3 {
        text-align: center;
    }
    .development {
        width: 80%;
        text-align: center;
    }

    .designGrid {
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
       
    }

    .designGrid, .toolsGrid {
        max-width: 350px;
        margin: 0px auto;
    }

    .creativeContainer {
        width: 90px;
        margin: 10px auto;
    }
    .designContainer {
        display: flex;
        margin: 0px auto 0px auto;
        width: 80%;
        justify-content: space-evenly;
    }

}

@media only screen and (min-width: 1200px) {
.toolsGrid, .designGrid {
    grid-template-columns: 1fr 1fr 1fr !important;
}

.skillsSection {
    width: 55%;
}
}

