.html {
    background-image: url(../assets/icons/html.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.css {
    background-image: url(../assets/icons/css.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.bootstrap {
    background-image: url(../assets/icons/bootstrap.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.axios {
    background-image: url(../assets/icons/axios.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.trello {
    background-image: url(../assets/icons/trello.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.vercel {
    background-image: url(../assets/icons/vercel.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.commercejs {
    background-image: url(../assets/icons/commercejs.jpg);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.projectIcon p {
    max-width: 100px;
    font-weight: bold;
}

.materialui {
    background-image: url(../assets/icons/materialui.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.firebase {
    background-image: url(../assets/icons/firebase.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.github {
    background-image: url(../assets/icons/github.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.gitkraken {
    background-image: url(../assets/icons/gitkraken.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.node {
    background-image: url(../assets/icons/node.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.mysql {
    background-image: url(../assets/icons/mysql.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.vue {
    background-image: url(../assets/icons/vue.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.mongodb {
    background-image: url(../assets/icons/mongodb.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.react {
    background-image: url(../assets/icons/react.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.next {
    background-image: url(../assets/icons/next.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.sass {
    background-image: url(../assets/icons/sass.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 10px;
    border-radius: 100%;
}

.next {
    background-image: url(../assets/icons/next.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.photoshop {
    background-image: url(../assets/icons/photoshop.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.figma {
    background-image: url(../assets/icons/figma.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}

.docker {
    background-image: url(../assets/icons/docker.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}
.styledcomponents {
    background-image: url(../assets/icons/styledcomponents.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}
.typescript {
    background-image: url(../assets/icons/typescript.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}
.premierepro {
    background-image: url(../assets/icons/premierepro.png);
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
}
